<template>
    <div>
        <!-- <myBreadcrumb :nav="nav"></myBreadcrumb> -->
        <div class="filter">
            <div>
                <img src="@/assets/images/Frame625.png" alt="" />
                <span class="title">我的积分：{{ userInfo.score || 0 }}</span>
                <span class="tit">分值范围</span>
                <span class="item" :class="filter === 0 ? 'active' : ''" @click="(filter = 0), (startCore = 0), (endCore = 3000), getGoodsList()">0-3000</span>
                <span class="item" :class="filter === 1 ? 'active' : ''" @click="(filter = 1), (startCore = 3000), (endCore = 5000), getGoodsList()">3000-5000</span>
                <span class="item" :class="filter === 2 ? 'active' : ''" @click="(filter = 2), (startCore = 5000), (endCore = 10000), getGoodsList()">5000-10000</span>
            </div>
            <el-input prefix-icon="el-icon-search" placeholder="请输入搜索内容" v-model="search" clearable></el-input>
        </div>
        <div class="classify">
            <div class="left">
                <div v-for="(item, index) in classList" :key="index" :class="classify == item.id ? 'active' : ''" class="item" @click="(classify = item.id), getGoodsList()">{{ item.name }}</div>
            </div>
            <div class="swiper">
                <el-carousel trigger="click" height="400px" loop>
                    <el-carousel-item v-for="(item, index) in lunbo" :key="index" @click.native="$router.push({ name: 'bankDetail', query: { id: item.id } })">
                        <img :src="item.picUrl" alt="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="butts">
            <div class="butt" @click="$router.push({ path: '/bankList', query: { types: '0' } })">
                <img src="@/assets/images/Group860.png" alt="" />
                <span>我能兑换</span>
            </div>
            <div class="butt" @click="$router.push({ path: '/bankList', query: { types: '1' } })">
                <img src="@/assets/images/Group861.png" alt="" />
                <span>折扣专区</span>
            </div>
            <div class="butt" @click="$router.push({ path: '/bankList', query: { types: '2' } })">
                <img src="@/assets/images/Group863.png" alt="" />
                <span>新品推荐</span>
            </div>
        </div>
        <div class="tabs">
            <span :class="{ active: type === 0 }" @click="(type = 0), getGoodsList()">最新上架</span>
            <span :class="{ active: type === 1 }" @click="(type = 1), getGoodsList()">精选好物</span>
        </div>
        <div class="list">
            <div @click="$router.push({ name: 'bankDetail', query: { id: item.id } })" class="item" v-for="(item, index) in list" :key="index">
                <img :src="item.picUrl" alt="" />
                <div class="title">{{ item.name }}</div>
                <div class="jifen">{{ item.exchangeScore }}积分</div>
            </div>
        </div>
        <el-pagination @size-change="getGoodsList" @current-change="getGoodsList" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getGoodsList, getClassificationList } from '@/api';
export default {
    name: 'bank',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            list: [],
            classList: [],
            nav: [
                {
                    title: '首页',
                    to: '/',
                },
                {
                    title: '文化银行',
                },
            ],
            type: 0,
            search: '',
            filter: 0, // 分值范围
            classify: null, // 分类
            startCore: 0, // 开始分值
            endCore: 3000, // 结束分值
            userInfo: {},
            lunbo: [],
            show: true,
        };
    },
    watch: {
        search() {
            this.getGoodsList();
        },
    },
    created() {
        this.getGoodsList();
        this.getClassificationList();
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    methods: {
        getData() {
            console.log(this.limit, this.page);
        },
        getGoodsList() {
            getGoodsList({
                pageNum: this.page,
                pageSize: this.limit,
                startCore: this.startCore,
                endCore: this.endCore,
                classificationId: this.classify,
                isRecommend: this.type,
            }).then((res) => {
                console.log(res);
                this.list = res.rows;
                this.total = res.total;
                //  取5条数据
                if (this.show) {
                    this.lunbo = this.list.slice(0, 5);
                }
                this.show = false;
            });
        },
        getClassificationList() {
            getClassificationList().then((res) => {
                console.log(res);
                this.classList = res.data;
            });
        },
    },
};
</script>

<style scoped lang="less">
.filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;

    img {
        width: 21px;
        height: 21px;
        margin-right: 5.5px;
    }

    div {
        display: flex;
        align-items: center;
    }

    .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        color: #333333;
        margin-right: 24px;
    }

    .tit {
        font-size: 16px;
        line-height: 23px;
        color: #eda200;
    }

    .item {
        font-size: 14px;
        background: rgba(237, 162, 0, 0.1);
        color: #eda200;
        margin-left: 8px;
        padding: 4px 18px;
        border-radius: 2px;
        cursor: pointer;
    }

    .active {
        color: #ffffff;
        background: #eda200;
    }

    ::v-deep .el-input {
        width: 240px;
        height: 30px;
        border-radius: 50px;
    }

    ::v-deep .el-input__inner {
        border-radius: 20px;
        height: 30px;
    }

    ::v-deep .el-input__icon {
        line-height: 30px;
        color: #eda200;
    }

    .butt {
        padding: 4px 12px;
        background: #f33f3e;
        border-radius: 2px;
        color: #ffffff;
        cursor: pointer;
    }
}
.classify {
    display: flex;
    justify-content: space-between;
    background: #eda200;

    .left {
        width: 23%;
        text-align: center;
        height: 400px;
        overflow-y: scroll;
        //隐藏滚动条
        ::-webkit-scrollbar {
            display: none;
        }
        .item {
            padding: 15px 0;
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
        }
        .active {
            //color: #ffffff;
            //background: #ffffff;
            background-color: #d69200 !important;
        }
    }

    .swiper {
        width: 77%;
        height: 400px;
        background: #f5f5f5;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
.butts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    height: 120px;
    margin-top: 24px;
    .butt {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        img {
            width: 40px;
            height: 40px;
        }
        span {
            font-size: 24px;
            line-height: 35px;
            color: #ffffff;
            margin-left: 8px;
        }
    }
    .butt:nth-child(1) {
        background: linear-gradient(119.69deg, #0063ff 38.01%, #66a1ff 100.04%);
    }
    .butt:nth-child(2) {
        background: linear-gradient(111.27deg, #f23e3d 31.25%, #f98240 100%);
    }
    .butt:nth-child(3) {
        background: linear-gradient(119.69deg, #2db30b 38.01%, #98d03c 100.04%);
    }
}
.tabs {
    display: flex;
    align-items: center;
    padding-top: 24px;
    overflow: hidden;
    border-radius: 3px;
    cursor: pointer;

    span {
        padding: 6.5px 10px;
        font-size: 14px;
        line-height: 17px;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #fff;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-radius: 3px;
        border-color: #dcdfe6;
    }

    .active {
        color: #fff;
        background: #eda200;
        border-color: #eda200;
    }
}
.list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    .item {
        margin-top: 24px;
        background: #ffffff;
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid #ebebeb;
        img {
            width: 100%;
            height: 210px;
            object-fit: cover;
        }
        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            height: 60px;
            width: 100%;
            color: #333333;
            padding: 12px;
            .ellipsisLine(2);
        }
        .jifen {
            font-weight: 700;
            font-size: 16px;
            line-height: 23px;
            color: #eda200;
            margin-top: 16px;
            padding: 0 0 12px 12px;
        }
    }
}
</style>
